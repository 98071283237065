import React, { Component, Fragment } from 'react'
import { BrowserRouter as Router, NavLink, Route } from 'react-router-dom'
import { Container, Nav, Navbar, NavItem } from 'react-bootstrap'

import 'react-notifications/lib/notifications.css';

import Clients from './components/Clients'
import RoleMgr from './components/Role'
import api from './tools/api/axios'
import EnvView from './components/EnvView';

class App extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      version: {},
      versionStr: "unknown",
      latestTags: [],
      latestTagsStr: "",
      loggedin: false,
    }
    this.getVersion()
    this.getRole()
    this.getLatestTag()
    this.checkCookie = this.checkCookie.bind(this)
    this.toggleLogin = this.toggleLogin.bind(this)
  }

  componentDidMount(){
    this.checkCookie()
  }

  toggleLogin(){
    this.checkCookie()
  }

  checkCookie(){
    if (document.cookie.split(';').some((item) => item.trim().includes('loggedin=true'))) {
      this.setState({loggedin: true})
    }else{
      this.setState({loggedin: false})
    }
  }

  getLatestTag () {
    return api.get('/pkgs/get-latest-tag').then(success => {
      let latestTags = success.data
      let latestTagsStr = ""
      try {
        if(Array.isArray(latestTags)) {
          if( latestTags.length > 0 ) {
            if(latestTags[0].IsTag) {
              latestTagsStr = latestTags[0].Name 
            }            
            if( (latestTags.length > 1) || !latestTags[0].IsTag) {
              latestTagsStr = latestTagsStr + "*"
            }
            latestTagsStr = latestTagsStr + ":"
          }
        }
      } catch (error) {}
      this.setState(Object.assign({}, this.state,
        {latestTags: latestTags, latestTagsStr: latestTagsStr}))
      })

  }

  getVersion () {
    return api.get('/version').then(success => {
      var version = success.data
      var versionStr = "unknown"
      if(version)  {
        versionStr = version["Version"]
      }
      this.setState(Object.assign({}, this.state,
        {version: version, versionStr: versionStr}))
    }, err => {
      console.error(`Encountered error getting version ${err}`)
      this.setState({ loading: false })
    }).catch(err => {
      console.error(`Caught error getting version ${err}`)
      this.setState({ loading: false })
      throw err
    })
  }

  getRole () {
    return api.get('/pkgs/get-role-type').then(success => {
      var roleType = success.data
      if(roleType === 'FirstFloorRole')  {
        document.title = "First Floor - App Repository"
      }
    }, err => {
      console.error(`Encountered error getting role ${err}`)
    }).catch(err => {
      console.error(`Caught error getting role ${err}`)
      throw err
    })
  }

  render () {
    return (
      <Router basename={'/w/apprepo'}>
        <div className='App'>
          <Navbar variant='dark' bg='dark'>
            <Navbar.Brand>App Repository{  "(" + this.state.latestTagsStr + this.state.versionStr + ")"}</Navbar.Brand>
            <Navbar.Toggle aria-controls='basic-navbar-nav' />
            <Navbar.Collapse id='basic-navbar-nav'>
              <Nav className='mr-auto'>
                {
                  <Fragment>
                    <NavItem>
                      <NavLink exact to='/' className='nav-link' activeClassName='active'>Apps</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink exact to='/clients' className='nav-link' activeClassName='active'>Clients</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink exact to='/env' className='nav-link' activeClassName='active'>Env</NavLink>
                    </NavItem>
                  </Fragment>
                }
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <p>&nbsp;</p>
          <Container>
            <Route exact path={'/'} component={RoleMgr} />
            <Route exact path={'/clients'} component={Clients} />
            <Route exact path={'/env'} component={EnvView} />
          </Container>
        </div>
      </Router>
    )
  }
}

export default App
